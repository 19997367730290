import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useFormik } from 'formik'
import { useDegradationTypeFindAllQuery } from 'src/modules/degradationTypes/application/DegradationTypeQueries'
import { DegradationType } from 'src/modules/degradationTypes/domain/DegradationType'
import {
	HttpDegradationTypeRepository,
} from 'src/modules/degradationTypes/infrastructure/HttpDegradationTypeRepository'
import { CorporateIncidentSaveRequest } from 'src/modules/incidents/application/dto/CorporateIncidentSaveRequest'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { NotificationGroup } from 'src/modules/notificationGroup/domain/NotificationGroup'
import {
	HttpNotificationGroupRepository,
} from 'src/modules/notificationGroup/infrastructure/HttpNotificationGroupRepository'
import {
	useNotificationLanguageFindAllQuery,
} from 'src/modules/notificationLanguages/application/NotificationLanguageQueries'
import { NotificationLanguage } from 'src/modules/notificationLanguages/domain/NotificationLanguage'
import {
	HttpNotificationLanguageRepository,
} from 'src/modules/notificationLanguages/infrastructure/HttpNotificationLanguageRepository'
import { useReportTypeFindAllQuery } from 'src/modules/reportTypes/application/ReportTypeQueries'
import { ReportType } from 'src/modules/reportTypes/domain/ReportType'
import { HttpReportTypeRepository } from 'src/modules/reportTypes/infrastructure/HttpReportTypeRepository'
import { ServicesCustomer } from 'src/modules/servicesCustomer/domain/ServicesCustomer'
import {
	HttpServicesCustomerRepository,
} from 'src/modules/servicesCustomer/infrastructure/HttpServicesCustomerRepository'
import {
	AuthContext,
	emptyUfinetSelectOption,
	IUfinetSelectOption,
	onFormikChanges,
	onFormikTextChanges,
	useInternalUser,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'
import * as Yup from 'yup'

import {
	ContactSelect,
	ContactSelectHandle,
	DatePicker,
	DatePickerTypeEnum,
	emptyClientSelectOption,
	Filter,
	IDisabledOptions,
	IFilterHiddenOptions,
	IFilterState,
	IRequiredOptions,
	UfinetBox,
	UfinetButton,
	UfinetInput,
	UfinetSectionBox,
	UfinetSelect,
} from 'ufinet-web-components'
import { REPORT_TYPES_IDS } from 'src/utils/constants'

const CreateCorporateIncidentPage: FC = () => {
	const translate = useTranslator()
	const navigate = useNavigate()
	const authData = useContext(AuthContext)
	const language = useLang()
	const isInternal = useInternalUser()

	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const notificationGroupRepository = useMemo(() => HttpNotificationGroupRepository(authData), [authData])
	const servicesCustomerRepository = useMemo(() => HttpServicesCustomerRepository(authData), [authData])
	const reportTypeRepository = useMemo(() => HttpReportTypeRepository(authData), [authData])
	const degradationTypeRepository = useMemo(() => HttpDegradationTypeRepository(authData), [authData])
	const notificationLangRepository = useMemo(() => HttpNotificationLanguageRepository(authData), [authData])

	const nowDate = useMemo(() => new Date(), [])

	const emptyNumberUfinetSelectOption: IUfinetSelectOption<number> = {
		label: '',
		value: 0,
	}

	const contactRef = useRef<ContactSelectHandle>(null)

	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

	const [notificationGroups, setNotificationGroups] = useState<IUfinetSelectOption[]>()
	const [servicesCustomer, setServicesCustomer] = useState<IUfinetSelectOption[]>()
	const [reportTypes, setReportTypes] = useState<IUfinetSelectOption[]>()
	const [degradationTypes, setDegradationTypes] = useState<IUfinetSelectOption[]>()
	const [notificationLangs, setNotificationLangs] = useState<IUfinetSelectOption[]>()
	const [selectedReportType, setSelectedReportType] = useState<IUfinetSelectOption>()
	const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout>()

	const onChange = useCallback(onFormikChanges, [])
	const onTextChange = useCallback(onFormikTextChanges, [])

	const getDegradationTypeSchemaValidation: () => Yup.ObjectSchema<
		{ label: string | null | undefined } | null,
		Yup.AnyObject,
		{ label: undefined } | null,
		''
	> = () =>
		selectedReportType?.value == REPORT_TYPES_IDS.DEGRADATION.toString()
			? Yup.object()
				.nullable()
				.shape({
					label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
				})
			: Yup.object().nullable().shape({
				label: Yup.string().nullable(),
			})

	const validationSchema = Yup.object().shape({
		reportContactId: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		clientSelect: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		notificationLang: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		affectedService: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		reportType: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		countrySelect: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		corporateGroupSelect: Yup.object().shape({
			label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
		}),
		degradationType: getDegradationTypeSchemaValidation(),
		incidentDescription: Yup.string().required(translate('ERROR.REQUIRED')),
		detectionTimestamp: Yup.string().required(translate('ERROR.REQUIRED')),
		reportEmail: Yup.string().required(translate('ERROR.REQUIRED')),
		reportPhone: Yup.string()
			.required(translate('ERROR.REQUIRED'))
			.matches(/^\+?(\d{1,3})?[-. (]*\d{1,4}[-. )]*(\d{1,4}[-. ]*){1,4}\d{1,4}$/, translate('ERROR.PHONE')),
	})

	const sendData = () => {
		setButtonDisabled(true)
		const request = CorporateIncidentSaveRequest.fromValues(formik.values)
		incidentRepository
			.saveCorporateIncident(request)
			.then(() => {
				toast.success(translate('TICKET.SAVE.SUCCESS'))
				navigate('/list-tickets')
			})
			.catch(() => {
				toast.error(translate('TICKET.SAVE.ERROR'))
				setButtonDisabled(false)
			})
	}

	const initialValues = useMemo(
		() => ({
			reportContactId: emptyUfinetSelectOption,
			reportEmail: authData.userData?.username || '',
			notificationGroup: emptyUfinetSelectOption,
			reportPhone: '',
			creationDate: nowDate,
			notificationLang: emptyNumberUfinetSelectOption,
			affectedService: emptyUfinetSelectOption,
			internalCustomerTicketNumber: '',
			reportType: emptyNumberUfinetSelectOption,
			degradationType: emptyNumberUfinetSelectOption,
			detectionTimestamp: undefined as unknown as Date,
			incidentDescription: '',
			visitDate: undefined as unknown as Date,
			visitContact: '',
			visitPhone: '',
			visitRequirements: '',
			// fichero: '',
			countrySelect: emptyUfinetSelectOption,
			corporateGroupSelect: emptyUfinetSelectOption,
			clientSelect: emptyClientSelectOption,
			emailUserApplication: authData.userData?.username || '',
		}),
		[authData.userData],
	)

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	const filterState: IFilterState = {
		countrySelect: null,
		corporateGroupSelect: null,
		clientSelect: null,
	}

	const requiredOptions: IRequiredOptions = {
		requiredCountry: true,
		requiredCorporateGroup: true,
		requiredClient: true,
		requiredContact: false,
		requiredReference: false,
		requiredFinalClient: false,
	}

	const hiddenOptions: IFilterHiddenOptions = {
		hideCountry: false,
		hideCorporateGroup: false,
		hideClient: false,
		hideContact: true,
		hideReference: true,
		hideFinalClient: true,
		hideSubmit: true,
	}

	const disabledOptions: IDisabledOptions = {
		allowCountrySelection: true,
		allowCorporateGroupSelection: Boolean(formik.values.countrySelect.value),
		allowClientSelection: Boolean(formik.values.corporateGroupSelect.value),
		allowContact: false,
		allowReference: false,
		allowFinalClient: false,
		allowSubmit: false,
	}

	const { isLoading: loadingReportTypes } = useReportTypeFindAllQuery(reportTypeRepository, {
		onSuccess: (params: ReportType[]): void => {
			const mappedData = params.map((item) => ReportType.mapReportTypeToSelectOption(item, language))
			setReportTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.REPORT_TYPE')),
	})
	const { isLoading: loadingDegradationTypes } = useDegradationTypeFindAllQuery(degradationTypeRepository, {
		onSuccess: (params: DegradationType[]): void => {
			const mappedData = params.map((item) => DegradationType.mapDegradationTypeToSelectOption(item, language))
			setDegradationTypes(mappedData)
		},
		onError: () => toast.error(translate('FETCH.ERROR.DEGRADATION_TYPE')),
	})
	const { isLoading: loadingNotificationLangs } = useNotificationLanguageFindAllQuery(notificationLangRepository, {
		onSuccess: (params: NotificationLanguage[]): void => {
			const mappedData = params.map((item) =>
				NotificationLanguage.mapNotificationLanguageToSelectOption(item, language),
			)
			setNotificationLangs(mappedData)
		},
	})

	const getServicesCustomer = (clientId: string, global: string = '') => {
		servicesCustomerRepository
			.corporateFindAll(clientId, language, global)
			.then((res) => {
				const mappedData = ServicesCustomer.mapServicesCustomerToSelectOption(res)
				setServicesCustomer(mappedData)
			})
			.catch(() => {
				toast.error(translate('FETCH.ERROR.AFFECTED_SERVICE'))
			})
	}

	const getNotificationGroups = (corporativeGroupId: string) => {
		notificationGroupRepository
			.findAll(corporativeGroupId)
			.then((params) => {
				const mappedData = params.map((item: { notificationGroupId: string; notificationGroupName: string }) =>
					NotificationGroup.mapNotificationGroupToSelectOption(item),
				)
				setNotificationGroups(mappedData)
			})
			.catch(() => {
				toast.error(translate('FETCH.ERROR.NOTIFICATION_GROUP'))
			})
	}

	useEffect(() => {
		if (formik.values.clientSelect.value) {
			const request = {
				clientIds: [formik.values.clientSelect.value],
			}
			contactRef.current?.fillSelect(request)
		}
	}, [formik.values.clientSelect])

	const handleInputChange = useCallback(
		(global) => {
			if (inputTimeout) {
				clearTimeout(inputTimeout)
			}

			const timeout = setTimeout(() => {
				getServicesCustomer(formik.values.clientSelect.value, global)
			}, 500)

			setInputTimeout(timeout)
		},
		[formik.values.clientSelect.value, getServicesCustomer, inputTimeout],
	)

	return (
		<UfinetBox>
			<form onSubmit={formik.handleSubmit}>
				<UfinetSectionBox title="ticket_info" className="mb-5">
					<h4>{translate('TICKET.NEW.TITLE.TICKET.INFO')}</h4>
					<div>
						<Filter
							internalUser={isInternal}
							setFilter={() => filterState}
							required={requiredOptions}
							hidden={hiddenOptions}
							disabled={disabledOptions}
							afterCountryChange={onChange(formik, 'countrySelect')}
							afterCorporateGroupChange={(val) => {
								onChange(formik, 'corporateGroupSelect')(val)
								if (val) {
									getNotificationGroups(val.value)
								} else {
									setNotificationGroups([])
								}
							}}
							afterClientChange={(val) => {
								onChange(formik, 'clientSelect')(val)
								if (val) {
									getServicesCustomer(val.value)
								} else {
									setServicesCustomer([])
								}
							}}
						/>
					</div>
					<div className="row">
						<ContactSelect
							className="col-12 col-md-4 mb-5"
							ref={contactRef}
							isDisabled={!formik.values.clientSelect.value}
							error={formik.errors.reportContactId?.label}
							value={formik.values.reportContactId}
							labelTitle={translate('TICKET.NEW.REPORT.USER')}
							requiredIcon
							onChange={onChange(formik, 'reportContactId')}
						/>
						<UfinetInput
							type="text"
							value={formik.values.reportEmail}
							id="report_email"
							isDisabled
							requiredIcon
							solid={false}
							error={formik.errors.reportEmail}
							labelTitle={translate('TICKET.NEW.REPORT.EMAIL')}
							className="col-12 col-md-4 mb-5"
							onChange={onTextChange(formik, 'reportEmail')}
						/>
						<UfinetSelect
							options={notificationGroups}
							value={formik.values.notificationGroup}
							id="notification_group"
							labelTitle={translate('TICKET.NEW.NOTIFICATION_GROUP')}
							className="col-12 col-md-4 mb-5"
							onChange={onChange(formik, 'notificationGroup')}
							isDisabled={!formik.values.corporateGroupSelect.value}
						/>
					</div>
					<div className="row">
						<UfinetInput
							type="text"
							value={formik.values.reportPhone}
							id="report_phone"
							requiredIcon
							solid={false}
							error={formik.errors.reportPhone}
							labelTitle={translate('TICKET.NEW.REPORT.PHONE')}
							className="col-12 col-md-4"
							onChange={onTextChange(formik, 'reportPhone')}
						/>
						<DatePicker
							value={formik.values.creationDate}
							timeFormat="24"
							type={DatePickerTypeEnum.DATE_TIME}
							id="creation_timestamp"
							label={translate('TICKET.NEW.CREATION_DATE')}
							className="col-12 col-md-4"
							disabled={true}
							onChange={() => {
							}}
						/>
						<UfinetSelect
							options={notificationLangs}
							isLoadingOptions={loadingNotificationLangs}
							value={formik.values.notificationLang}
							error={formik.errors.notificationLang?.label}
							id="notification_lang"
							requiredIcon
							labelTitle={translate('TICKET.NEW.NOTIFICATION_LANGUAGE')}
							className="col-12 col-md-4"
							onChange={onChange(formik, 'notificationLang')}
						/>
					</div>
				</UfinetSectionBox>

				<UfinetSectionBox title="incident_info" className="mb-5 d-flex flex-column">
					<h4>{translate('TICKET.NEW.TITLE.INCIDENT.INFO')}</h4>
					<div className="row">
						<UfinetSelect
							options={servicesCustomer}
							value={formik.values.affectedService}
							id="affected_service"
							labelTitle={translate('TICKET.NEW.AFFECTED_SERVICE')}
							requiredIcon
							className="col-12 col-md-6 mb-5"
							onChange={onChange(formik, 'affectedService')}
							onInputChange={handleInputChange}
							error={formik.errors.affectedService?.label}
							isDisabled={!formik.values.clientSelect.value}
						/>
						<UfinetInput
							type="text"
							value={formik.values.internalCustomerTicketNumber}
							id="internal_ticket_number"
							solid={false}
							labelTitle={translate('TICKET.NEW.INTERNAL_TICKET_NUMBER')}
							className="col-12 col-md-6 mb-5"
							onChange={onTextChange(formik, 'internalCustomerTicketNumber')}
							error={formik.errors.internalCustomerTicketNumber}
						/>
					</div>
					<div className="row">
						<UfinetSelect
							options={reportTypes}
							value={formik.values.reportType}
							id="report_type"
							labelTitle={translate('TICKET.NEW.REPORT.TYPE')}
							requiredIcon
							className="col-12 col-md-4 mb-5"
							isLoadingOptions={loadingReportTypes}
							onChange={(e) => {
								onChange(formik, 'reportType')(e)
								onChange(formik, 'degradationType')(emptyUfinetSelectOption)
								setSelectedReportType(e as IUfinetSelectOption)
							}}
							error={formik.errors.reportType?.label}
						/>
						<UfinetSelect
							options={degradationTypes}
							value={formik.values.degradationType}
							id="degradation_type"
							labelTitle={translate('TICKET.NEW.DEGRADATION_TYPE')}
							requiredIcon={selectedReportType?.value == REPORT_TYPES_IDS.DEGRADATION.toString()}
							className="col-12 col-md-4 mb-5"
							isLoadingOptions={loadingDegradationTypes}
							isDisabled={formik.values.reportType.value !== REPORT_TYPES_IDS.DEGRADATION}
							onChange={onChange(formik, 'degradationType')}
							error={formik.errors.degradationType?.label}
						/>
						<DatePicker
							type={DatePickerTypeEnum.DATE_TIME}
							value={formik.values.detectionTimestamp}
							timeFormat="24"
							id="detection_timestamp"
							label={translate('TICKET.NEW.DETECTION_TIMESTAMP')}
							labelIconRequired
							className="col-12 col-md-4 mb-5"
							onChange={onTextChange(formik, 'detectionTimestamp')}
							error={`${formik.errors.detectionTimestamp || ''}`}
							max={nowDate}
						/>
					</div>
					<div className="row">
						<UfinetInput
							type="text"
							value={formik.values.incidentDescription}
							id="incident_description"
							labelTitle={translate('TICKET.NEW.INCIDENT_DESCRIPTION')}
							requiredIcon
							solid={false}
							className="col"
							onChange={onTextChange(formik, 'incidentDescription')}
							error={formik.errors.incidentDescription}
						/>
					</div>
				</UfinetSectionBox>

				<UfinetSectionBox title="contact_info" className="mb-5">
					<h4>{translate('TICKET.NEW.TITLE.VISIT.INFO')}</h4>
					<div className="row">
						<DatePicker
							type={DatePickerTypeEnum.DATE_TIME}
							timeFormat="24"
							value={formik.values.visitDate}
							id="visit_timestamp"
							label={translate('TICKET.NEW.VISIT.TIMESTAMP')}
							className="col-12 col-md-4 mb-5"
							onChange={onTextChange(formik, 'visitDate')}
							min={nowDate}
						/>
						<UfinetInput
							type="text"
							value={formik.values.visitContact}
							id="visit_contact"
							solid={false}
							labelTitle={translate('TICKET.NEW.VISIT.CONTACT')}
							className="col-12 col-md-4 mb-5"
							onChange={onTextChange(formik, 'visitContact')}
						/>
						<UfinetInput
							type="text"
							value={formik.values.visitPhone}
							id="visit_phone"
							solid={false}
							labelTitle={translate('TICKET.NEW.VISIT.PHONE')}
							className="col-12 col-md-4 mb-5"
							onChange={onTextChange(formik, 'visitPhone')}
						/>
					</div>
					<div className="row">
						<UfinetInput
							type="text"
							value={formik.values.visitRequirements}
							id="visit_requirements"
							solid={false}
							labelTitle={translate('TICKET.NEW.VISIT.REQUIREMENTS')}
							className="col"
							onChange={onTextChange(formik, 'visitRequirements')}
						/>
					</div>
					{/* <div className="d-flex flex-row gap-9 w-100 mt-3">
						<label htmlFor="fichero">{translate('TICKET.NEW.FILE')}</label>
						<input
							type="file"
							name="fichero"
							value={formik.values.fichero}
							onChange={onTextChange(formik, 'fichero')}
						/>
					</div> */}
				</UfinetSectionBox>

				<UfinetSectionBox>
					<div className="d-flex flex-row gap-9 flex-center">
						<UfinetButton content={translate('BUTTON.SEND')} type="submit" isDisabled={buttonDisabled} />
						<UfinetButton content={translate('BUTTON.CANCEL')} onClick={() => navigate('/')} />
					</div>
				</UfinetSectionBox>
			</form>
		</UfinetBox>
	)
}

export { CreateCorporateIncidentPage }
