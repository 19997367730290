import { AppLanguage } from 'ufinet-web-functions'

export const getLocalizedName = (
	obj: { nameEs: string | null; nameEn: string | null; namePt: string | null },
	lang: AppLanguage
): string => {
	if (lang === 'en' && obj.nameEn) {
		return obj.nameEn
	}
	if (lang === 'pt' && obj.namePt) {
		return obj.namePt
	}
	return obj.nameEs ?? ''
}
