import { useQuery } from 'react-query'
import { DegradationType } from 'src/modules/degradationTypes/domain/DegradationType'
import { DegradationTypeRepository } from '../domain/repository/DegradationTypeRepository'

export const useDegradationTypeFindAllQuery = (
	degradationTypeRepository: DegradationTypeRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (degradationType: DegradationType[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('degradation-type-find-all-query', () => degradationTypeRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
