import { useQuery } from 'react-query'

import { ProjectType } from '../domain/ProjectType'
import { ProjectTypeRepository } from '../domain/repository/ProjectTypeRepository'

export const useProjectTypeFindAllQuery = (
	projectTypeRepository: ProjectTypeRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (projectType: ProjectType[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('project-type-find-all-query', () => projectTypeRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
