import { FC } from 'react'

import { useTranslator } from 'ufinet-web-functions'

import { UfinetInput, UfinetSectionBox } from 'ufinet-web-components'

interface DetailTicketIncidentInfoProps {
	visitDate: string | null
	visitContactName: string | null
	visitContactPhone: string | null
	visitRequirements: string | null
}

const DetailTicketVisitInfo: FC<DetailTicketIncidentInfoProps> = ({
	visitDate,
	visitContactName,
	visitContactPhone,
	visitRequirements,
}) => {
	const translate = useTranslator()
	return (
		<UfinetSectionBox title="visit_info" className="mb-5 p-5">
			<h4 className="detail-ticket-titles">{translate('TICKET.NEW.TITLE.VISIT.INFO')}</h4>
			<div className="row">
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET.NEW.VISIT.TIMESTAMP')}
					type="text"
					value={visitDate ?? ''}
					id="visiting_hours"
					solid={false}
					isDisabled
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET.NEW.VISIT.CONTACT')}
					type="text"
					value={visitContactName ?? ''}
					solid={false}
					isDisabled
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET.NEW.VISIT.PHONE')}
					type="text"
					value={visitContactPhone ?? ''}
					solid={false}
					isDisabled
				/>
			</div>
			<div className="row">
				<UfinetInput
					className="col-12 col-md mt-3 mb-4 mb-md-0"
					labelTitle={translate('TICKET_DETAILS.REQUIREMENTS')}
					type="text"
					value={visitRequirements ?? ''}
					solid={false}
					isDisabled
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { DetailTicketVisitInfo }
