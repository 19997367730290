import { useQuery } from 'react-query'

import { SolutionTypeRepository } from '../domain/repository/SolutionTypeRepository'
import { SolutionType } from '../domain/SolutionType'

export const useSolutionTypeFindAllQuery = (
	solutionTypeRepository: SolutionTypeRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (solutionType: SolutionType[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('solution-type-find-all-query', () => solutionTypeRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
