import { DataTableFilterMeta, DataTablePFSEvent, DataTableSortMeta } from 'primereact/datatable'
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormikProps, useFormik } from 'formik'
import { IncidentFindRequest } from 'src/modules/incidents/application/dto/IncidentFindRequest'
import { IncidentRow } from 'src/modules/incidents/domain/IncidentRow'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { AuthContext, Authority, PagingData, useInternalUser, useLang, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import {
	ClientSelectHandle,
	CorporateGroupSelectHandle,
	Table,
	UfinetBox,
	UfinetButton,
	UfinetSectionBox,
	UfinetSelectOptionValue,
} from 'ufinet-web-components'
import { ColumnsHiddenHandler } from './components/ColumnsHiddenHandler'
import { ListIncidentHeader } from './components/ListIncidentHeader'
import { incidentsColsData } from './IncidentColsData'
import { useListDataQueries } from './useListDataQueries'

type FormikValues = {
	countryId: { label: string; value: string }
	corporateGroupId: { label: string; value: string }
	customersId: UfinetSelectOptionValue
	dateFrom: Date | undefined
	dateTo: Date | undefined
	first: number
	rows: number
	sortField: string
	sortOrder: number
	filters: DataTableFilterMeta
	multiSortMeta: DataTableSortMeta[]
}

const ListIncidentsPage: FC = () => {
	const translate = useTranslator()
	const authData = useContext(AuthContext)
	const isInternal = useInternalUser()
	const navigate = useNavigate()
	const lang = useLang()

	const roles = authData.userData?.authorities || []
	const corporatePermissions = Authority.getGtiCorporatePermissions(roles)
	const ftthPermissions = Authority.getGtiFtthPermissions(roles)

	const [incidents, setIncidents] = useState<IncidentRow[]>([])
	const [loadingIncidents, setLoadingIncidents] = useState(false)
	const [visibleColumns, setVisibleColumns] = useState<{ [key: string]: boolean }>({
		tickerNumber: true,
		administrativeCode: true,
		title: true,
		country: false,
		customer: true,
		projectTypes: true,
		reportTypes: false,
		degradationTypes: false,
		affectationTypes: false,
		reportOrigins: false,
		internalClientTicketNumber: true,
		serviceTypes: false,
		incidentStatues: false,
		createdDate: true,
		solutionTypes: false,
		primaryCause: false,
		secondaryCause: false,
		imputableTo: false,
		serviceRestorationDate: false,
		statusCloseDate: false,
		totalImputableTime: false,
		rfoSentStatuses: false,
		rfoSentDate: false,
		descriptionDiscountTime: false,
	})

	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const groupSelectRef = useRef<CorporateGroupSelectHandle>(null)
	const clientSelectRef = useRef<ClientSelectHandle>(null)
	const {
		affectationTypes,
		degradationTypes,
		processStatus,
		projectTypes,
		reportOrigins,
		reportTypes,
		rfoSentStatus,
		serviceTypes,
		solutionTypes,
	} = useListDataQueries()

	const initialValues = useMemo(
		() => ({
			countryId: {},
			corporateGroupId: {},
			customersId: [],
			dateFrom: undefined as unknown as Date,
			dateTo: undefined as unknown as Date,
		}),
		[]
	)

	const differBy90DaysOrMore = (dateFrom: Date, dateTo: Date) => {
		const differenceInMilliseconds = Math.abs(dateTo.getTime() - dateFrom.getTime())
		const millisecondsInOneDay = 24 * 60 * 60 * 1000
		const differenceInDays = differenceInMilliseconds / millisecondsInOneDay
		return differenceInDays <= 90
	}

	const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePFSEvent>({} as DataTablePFSEvent)

	const defaultPaging: PagingData = useMemo(
		() => ({
			pageNumber: 0,
			pageSize: 10,
			totalElements: 0,
			totalPages: 1,
		}),
		[]
	)

	const [paging, setPaging] = useState<PagingData>(defaultPaging)

	const validationSchema = Yup.object().shape({
		dateFrom: Yup.date()
			.optional()
			.test('differBy90DaysOrMore', 'Error', function () {
				const { dateFrom, dateTo } = this.parent
				if (dateFrom && dateTo) {
					return differBy90DaysOrMore(new Date(dateFrom), new Date(dateTo))
				} else if (dateFrom) {
					return differBy90DaysOrMore(new Date(dateFrom), new Date())
				} else {
					return true
				}
			}),
		dateTo: Yup.date()
			.optional()
			.test('differBy90DaysOrMore', 'Error', function () {
				const { dateFrom, dateTo } = this.parent
				if (dateFrom && dateTo) {
					return differBy90DaysOrMore(new Date(dateFrom), new Date(dateTo))
				} else if (dateFrom) {
					return differBy90DaysOrMore(new Date(dateFrom), new Date())
				} else {
					return true
				}
			}),
	})

	const findTickets = (event: DataTablePFSEvent) => {
		setLoadingIncidents(true)

		const permissions = {
			corporate: corporatePermissions.canRead,
			ftth: ftthPermissions.canRead,
		}

		incidentRepository
			.findAll(
				IncidentFindRequest.fromValues(isInternal, authData.userData?.username, lang, formik.values, event, permissions)
			)
			.then((data) => {
				setIncidents(data.incidents)
				setPaging({
					pageNumber: data.pageNumber,
					pageSize: data.rowsPerPage,
					totalPages: data.totalPages,
					totalElements: data.totalRows,
				})
				setLoadingIncidents(false)
			})
			.catch(() => {
				toast.error(translate('ERROR.TICKET.FILTER'))
				setLoadingIncidents(false)
			})
	}

	const navigateTo = (row: IncidentRow) => {
		navigate(
			row?.projectType?.includes('FTTH')
				? `/ftth-tickets-detail/${row.incidentId}`
				: `/corporate-tickets-detail/${row.incidentId}`
		)
	}

	const formik: FormikProps<FormikValues> = useFormik({
		initialValues: {
			...initialValues,
			...{ first: 0, rows: 10, sortField: '', sortOrder: 0, filters: {}, multiSortMeta: {} as DataTableSortMeta[] },
		},
		validationSchema: validationSchema,
		onSubmit: findTickets,
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	useEffect(() => {
		findTickets(tablePFSEvent)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<UfinetBox>
			<form onSubmit={formik.handleSubmit}>
				<ListIncidentHeader
					formik={formik}
					clientSelectRef={clientSelectRef}
					groupSelectRef={groupSelectRef}
					findTickets={findTickets}
					tablePFSEvent={tablePFSEvent}
				/>

				<UfinetSectionBox className="mt-3">
					<Table
						cols={incidentsColsData(
							translate,
							navigateTo,
							visibleColumns,
							reportTypes,
							degradationTypes,
							affectationTypes,
							projectTypes,
							processStatus,
							reportOrigins,
							rfoSentStatus,
							serviceTypes,
							solutionTypes
						)}
						content={incidents}
						editDisabled={true}
						onFilterClear={() => {
							formik.resetForm()
							findTickets({} as DataTablePFSEvent)
						}}
						headerButtons={
							<div className="d-flex flex-row gap-3">
								<ColumnsHiddenHandler visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} />
								<UfinetButton
									icon={faRefresh}
									type="button"
									id="refresh-button"
									onClick={() => findTickets(tablePFSEvent)}
								/>
								{/*<UfinetButton icon={faDownload} />*/}
							</div>
						}
						afterTablePFSEvent={setTablePFSEvent}
						lazySettings={
							paging && {
								...paging,
								loading: loadingIncidents,
								onPage: (event: DataTablePFSEvent) => findTickets(event),
								onFilter: (event: DataTablePFSEvent) => findTickets(event),
								onSort: (event: DataTablePFSEvent) => findTickets(event),
							}
						}
					/>
				</UfinetSectionBox>
			</form>
		</UfinetBox>
	)
}

export { ListIncidentsPage }
