import { format } from 'date-fns';
import dayjs from 'dayjs';

export const convertToLocalTimeString = (date: Date | undefined) => {
	if (date) {
		return format(date, 'yyyy-MM-dd\'T\'HH:mm:ss');
	} else {
		return undefined;
	}
}

export const customISOString = (date: Date | undefined) => {
	if (date) {
		return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
	} else {
		return undefined;
	}
}

export const formatDate = (date: string) => {
	if (dayjs(date).isValid()) {
		return dayjs(date).format('DD-MM-YYYY HH:mm');
	} else {
		return date;
	}
}
