import { FC, useMemo, useState } from 'react'

import { FormikProps, useFormik } from 'formik'
import { IncidentActivityDTO } from 'src/modules/incidents/application/dto/IncidentActivityDTO'
import { VALID_TICKET_STATUS_IDS } from 'src/utils/constants'
import { useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

import Pagination from '@Pages/tickets/details/components/detailTicketNotes/components/Pagination'
import { Loading, UfinetBox, UfinetButton, UfinetSectionBox } from 'ufinet-web-components'
import { Note } from './components/Note'
import { NotesModal } from './components/NotesModal'

type DetailTicketNotesProps = {
	activities: IncidentActivityDTO[]
	loading: boolean
	currentPage: number
	totalPages: number
	onPageChange: (newPage: number) => void
	onCreateComment: (subject: string, description: string) => Promise<void>
	isTicketInProcess: boolean
	ticketStatusId: number
}

type FormikValues = {
	subject: string
	description: string
}

const DetailTicketNotes: FC<DetailTicketNotesProps> = ({
	activities = [],
	loading,
	currentPage,
	// totalPages,
	onPageChange,
	onCreateComment,
	isTicketInProcess,
	ticketStatusId,
}) => {
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [submitting, setSubmitting] = useState<boolean>(false)
	const translate = useTranslator()

	const itemsPerPage = 5

	const paginatedActivities = useMemo(() => {
		const startIndex = (currentPage - 1) * itemsPerPage
		const endIndex = startIndex + itemsPerPage
		return activities.slice(startIndex, endIndex)
	}, [activities, currentPage])

	const totalPagesCalculated = useMemo(() => {
		return Math.ceil(activities.length / itemsPerPage)
	}, [activities])

	const initialValues = {
		subject: '',
		description: '',
	}

	const validationSchema = Yup.object({
		subject: Yup.string().required(translate('ERROR.REQUIRED')),
		description: Yup.string().required(translate('ERROR.REQUIRED')),
	})

	const onSubmit = async (values: FormikValues) => {
		setOpenModal(false)
		setSubmitting(true)
		try {
			await onCreateComment(values.subject, values.description)
		} finally {
			setSubmitting(false)
			formik.resetForm()
		}
	}

	const formik: FormikProps<FormikValues> = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
		validateOnChange: false,
		validateOnBlur: true,
		enableReinitialize: true,
	})

	if (loading) {
		return (
			<UfinetBox className="detail-loading-container">
				<Loading userClasses="detail-loading" />
			</UfinetBox>
		)
	}

	return (
		<>
			<UfinetSectionBox title="ticket_notes" className="mb-5">
				<div className="d-flex justify-content-between align-items-center">
					<h4 className="detail-ticket-titles">{translate('TICKET_DETAILS.COMMENTS')}</h4>
					{VALID_TICKET_STATUS_IDS.includes(ticketStatusId) && (
						<div className="d-flex justify-content-end">
							<UfinetButton
								content={translate('TICKET_DETAILS.ADD_COMMENT')}
								onClick={() => setOpenModal(true)}
								isDisabled={submitting || isTicketInProcess}
							/>
						</div>
					)}
				</div>

				{isTicketInProcess ? (
					<div>{translate('TICKET_DETAILS.SYNC_IN_PROGRESS')}</div>
				) : (
					<>
						{paginatedActivities.length > 0 ? (
							paginatedActivities.map((activity, index) => (
								<Note
									key={activity.activityId}
									date={activity.createOn ? activity.createOn.toString() : ''}
									to={activity.ownerName || ''}
									subject={activity.subject || ''}
									content={activity.description || ''}
									createdBy={activity.ownerName || ''}
									isExternal={activity.externalId !== null}
									isFirstNote={index === 0}
									isLastNote={index === paginatedActivities.length - 1}
								/>
							))
						) : (
							<div>{translate('TICKET_DETAILS.NO_ACTIVITIES_FOUND')}</div>
						)}
					</>
				)}

				<Pagination currentPage={currentPage} totalPages={totalPagesCalculated} onPageChange={onPageChange} />
			</UfinetSectionBox>

			<NotesModal
				show={openModal}
				handleClose={() => setOpenModal(false)}
				formik={formik}
				onCreateComment={onCreateComment}
			/>
		</>
	)
}

export { DetailTicketNotes }
