import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { RfoSentStatusRepository } from '../domain/repository/RfoSentStatusRepository'
import { RfoSentStatus } from '../domain/RfoSentStatus'

export const HttpRfoSentStatusRepository: (authData: IAuthData) => RfoSentStatusRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}rfoSentStatus`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<RfoSentStatus[]> =>
			await FetchAPI.get(`${url}/GetRfoSentStatus`, {
				...fetchOptions,
				...options,
			}),
	}
}
