import { NotificationLanguage } from 'src/modules/notificationLanguages/domain/NotificationLanguage'
import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { NotificationLanguageRepository } from '../domain/repository/NotificationLanguageRepository'

export const HttpNotificationLanguageRepository: (authData: IAuthData) => NotificationLanguageRepository = (
	authData
) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}notificationLanguage`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<NotificationLanguage[]> =>
			await FetchAPI.get(`${url}/GetLanguagesNotifications`, {
				...fetchOptions,
				...options,
			}),
	}
}
