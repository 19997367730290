import { IUfinetSelectOption } from 'ufinet-web-components'

export class NotificationGroup {
	notificationGroupId: string
	name: string

	constructor(id: string, name: string) {
		this.notificationGroupId = id
		this.name = name
	}

	static mapNotificationGroupToSelectOption(self: NotificationGroup): IUfinetSelectOption {
		return {
			label: self.name,
			value: self.notificationGroupId,
		}
	}
}
