import { IUfinetSelectOption, useTranslator } from 'ufinet-web-functions'

export class RfoSentStatus {
	rfoSentStatus: number

	constructor(id: number) {
		this.rfoSentStatus = id
	}

	static mapRfoSentStatusToSelectOption(self: RfoSentStatus, labels: { notSent: string, sent: string }): IUfinetSelectOption {
		const label =
			self.rfoSentStatus === 0 ? labels.notSent : labels.sent
		return {
			label: label,
			value: self.rfoSentStatus.toString(),
		}
	}
}
