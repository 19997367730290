import { FC } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { CorporateTicketDetailPage } from '@Pages/tickets/details/CorporateTicketDetailPage';
import { FTTHTicketDetailPage } from '@Pages/tickets/details/FTTHTicketDetailPage';
import { ListIncidentsPage } from '@Pages/tickets/list/ListIncidentsPage';
import { CreateCorporateIncidentPage } from '@Pages/tickets/new/CreateCorporateIncidentPage';
import { CreateFtthIncidentPage } from '@Pages/tickets/new/CreateFtthIncidentPage';
import { CreateIncidentPage } from '@Pages/tickets/new/CreateIncidentPage';
import { AsideDefault } from '@Ui/layout/aside/AsideDefault';
import { AsideMobile } from '@Ui/layout/aside/AsideMobile';
import { SupportPage } from 'src/pages/knowledge/support/SupportPage';
import { WelcomeKitPage } from 'src/pages/knowledge/welcomekit/WelcomeKitPage';
import { BulkTicketsPage } from 'src/pages/tickets/bulk/BulkTicketsPage';
import { useTranslator } from 'ufinet-web-functions';

import { AuthRoute, MasterLayout, WelcomePage } from 'ufinet-web-components';
import {
  PATH_BULK_TICKETS,
  PATH_CORPORATE_TICKETS_DETAIL,
  PATH_FTTH_TICKETS_DETAIL,
  PATH_HOME,
  PATH_LIST_TICKETS,
  PATH_NEW_TICKET,
  PATH_NEW_TICKET_CORP,
  PATH_NEW_TICKET_FTTH,
  PATH_SUPPORT,
  PATH_WELCOME_KIT,
  routerNameMap
} from './routerNameMap';
import { routerPermissionMap } from './routerPermissionMap';

const PrivateRoutes: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				<Route
					path={PATH_HOME}
					element={<WelcomePage appName={translate('GTI.NAME')} message={translate('GTI.MESSAGE')} />}
				/>

				<Route
					path={PATH_NEW_TICKET}
					element={
						<AuthRoute authorizedRoles={routerPermissionMap.get(PATH_NEW_TICKET)!}>
							<CreateIncidentPage />
						</AuthRoute>
					}
				/>

				<Route path={PATH_NEW_TICKET_CORP} element={<CreateCorporateIncidentPage />} />
				<Route path={PATH_NEW_TICKET_FTTH} element={<CreateFtthIncidentPage />} />

				<Route path={`${PATH_CORPORATE_TICKETS_DETAIL}/:id`} element={<CorporateTicketDetailPage />} />
				<Route path={`${PATH_FTTH_TICKETS_DETAIL}/:id`} element={<FTTHTicketDetailPage />} />

				<Route path={PATH_BULK_TICKETS} element={<BulkTicketsPage />} />
				<Route path={PATH_LIST_TICKETS} element={<ListIncidentsPage />} />

				<Route path={PATH_SUPPORT} element={<SupportPage />} />
				<Route path={PATH_WELCOME_KIT} element={<WelcomeKitPage />} />

				<Route path="*" element={<Navigate to={PATH_HOME} replace />} />
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
