import * as ReactDOM from 'react-dom'

import 'ufinet-web-components/dist/index.css'

import '../sass/style.scss'

import { QueryClient, QueryClientProvider } from 'react-query'

import { GtiI18nProvider } from '@Components/common/i18n/GtiI18nProvider'
import { Provider } from 'react-redux'
import { AppRoutes } from '@Routing/AppRoutes'
import { AuthProvider } from 'ufinet-web-components'
import packageJson from '../../package.json'
import { store } from '@Store/store'
import { worker } from 'src/mocks/browser'

// Start the mocking conditionally
if (process.env.VITE_ENV === 'TEST') {
	worker.start()
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: true,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: false,
		},
	},
})

console.info(packageJson.version)

ReactDOM.render(
	<>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<AuthProvider>
					<GtiI18nProvider>
						<AppRoutes />
					</GtiI18nProvider>
				</AuthProvider>
			</Provider>
		</QueryClientProvider>
	</>,
	document.getElementById('root')
)
