import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
	PATH_LIST_TICKETS,
	PATH_NEW_TICKET,
	PATH_SUPPORT,
	PATH_WELCOME_KIT,
	routerNameMap,
} from '@Routing/protected/routerNameMap'
import { AuthContext, Authority, useTranslator } from 'ufinet-web-functions'

import { faCircleDot, faTicketSimple } from '@fortawesome/free-solid-svg-icons'

import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'

enum AsideMenu {
	NONE,
	MY_TICKETS,
	KNOWLEDGE_BASE,
}

const AsideMenuMain: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	const { userData } = useContext(AuthContext)

	const [active, setActive] = useState(AsideMenu.NONE)

	const activeMenu = (menu: AsideMenu) => () => setActive(menu)
	const isMyTicketsActive = () => active === AsideMenu.MY_TICKETS
	const isKnowledgeActive = () => active === AsideMenu.KNOWLEDGE_BASE

	const roles = userData?.authorities || []
	const corporatePermissions = Authority.getGtiCorporatePermissions(roles)
	const ftthPermissions = Authority.getGtiFtthPermissions(roles)

	const menuMyTicketsItems: IMenuItem[] = [
		{
			to: PATH_NEW_TICKET,
			title: routerNameMap.get(PATH_NEW_TICKET),
			icon: faCircleDot,
			hidden: !corporatePermissions.canWrite && !ftthPermissions.canWrite,
		},
		/*
		{ to: PATH_BULK_TICKETS, title: routerNameMap.get(PATH_BULK_TICKETS), icon: faCircleDot },
		*/
		{
			to: PATH_LIST_TICKETS,
			title: routerNameMap.get(PATH_LIST_TICKETS),
			icon: faCircleDot,
			hidden: !corporatePermissions.canRead && !ftthPermissions.canRead,
		},
	].map((it) => ({ ...it }))

	const menuKnowledgeBaseItems: IMenuItem[] = [
		{ to: PATH_SUPPORT, title: routerNameMap.get(PATH_SUPPORT), icon: faCircleDot },
		{ to: PATH_WELCOME_KIT, title: routerNameMap.get(PATH_WELCOME_KIT), icon: faCircleDot },
	].map((it) => ({ ...it }))
	return (
		<>
			<AsideMenuItemWithSub
				icon={<FontAwesomeIcon icon={faTicketSimple} className="fs-2x" />}
				title={translate('MENU.MY_TICKETS')}
				active={isMyTicketsActive()}
			>
				{menuMyTicketsItems
					.filter((item) => !item.hidden)
					.map((item) => (
						<AsideMenuItem
							routerLocation={location}
							key={item.to}
							to={item.to}
							icon={item.icon}
							title={item.title ? translate(item.title) : ''}
							onClick={activeMenu(AsideMenu.MY_TICKETS)}
						/>
					))}
			</AsideMenuItemWithSub>
			{/*//TODO: Uncomment when knowledge base is ready*/}
			{/*<AsideMenuItemWithSub
				icon={<FontAwesomeIcon icon={faBookOpen} className="fs-2x" />}
				title={translate('MENU.KNOWLEDGE')}
				active={isKnowledgeActive()}
			>
				{menuKnowledgeBaseItems
					.filter((item) => !item.hidden)
					.map((item) => (
						<AsideMenuItem
							routerLocation={location}
							key={item.to}
							to={item.to}
							icon={item.icon}
							title={item.title ? translate(item.title) : ''}
							onClick={activeMenu(AsideMenu.KNOWLEDGE_BASE)}
						/>
					))}
			</AsideMenuItemWithSub>*/}
		</>
	)
}

export { AsideMenuMain }
