import { NetworkElementType } from '../domain/NetworkElementType'
import { NetworkElementTypeRepository } from '../domain/repository/NetworkElementTypeRepository'
import { useQuery } from 'react-query'

export const useNetworkElementTypeFindAllQuery = (
	networkElementTypeRepository: NetworkElementTypeRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess: (networkElementTypes: NetworkElementType[]) => void
		onError: (error: Error) => void
	}
) => {
	return useQuery('network-element-type-find-all-query', () => networkElementTypeRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
