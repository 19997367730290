import { i18nMessages } from 'ufinet-web-components'
import gtiEnMessages from './en.json'
import gtiEsMessages from './es.json'
import gtiPtMessages from './pt.json'

export const gtiI18nMessages: i18nMessages = {
	en: {
		...gtiEnMessages,
	},
	es: {
		...gtiEsMessages,
	},
	pt: {
		...gtiPtMessages,
	},
}
