import { useQuery } from 'react-query'

import { RfoSentStatusRepository } from '../domain/repository/RfoSentStatusRepository'
import { RfoSentStatus } from '../domain/RfoSentStatus'

export const useRfoSentStatusFindAllQuery = (
	rfoSentStatusRepository: RfoSentStatusRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (rfoSentStatus: RfoSentStatus[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('rfo-sent-status-find-all-query', () => rfoSentStatusRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
