import { FC, useCallback } from 'react'
import { FormikProps } from 'formik'
import { onFormikTextChanges, useTranslator } from 'ufinet-web-functions'
import { UfinetButton, UfinetModal, UfinetInput } from 'ufinet-web-components'

type FormikValues = {
	subject: string;
	description: string;
};

type NotesModalProps = {
	show: boolean;
	handleClose: () => void;
	formik: FormikProps<FormikValues>;
	onCreateComment: (subject: string, description: string) => void;
};

const NotesModal: FC<NotesModalProps> = ({ show, handleClose, formik, onCreateComment }) => {
	const { values, errors, isValid, dirty, handleSubmit } = formik
	const translate = useTranslator()
	const onTextChange = useCallback(onFormikTextChanges, [])

	return (
		<UfinetModal
			size="lg"
			title={translate('TICKET_DETAILS.COMMENTS_MODAL_TITLE')}
			show={show}
			handleClose={handleClose}
			onHide={formik.handleReset}
		>
			<UfinetInput
				type="text"
				value={values.subject}
				id="subject"
				requiredIcon
				solid={false}
				error={errors.subject}
				labelTitle={translate('TICKET_DETAILS.SUBJECT')}
				className="mb-3 w-100"
				onChange={onTextChange(formik, 'subject')}
			/>

			{/*//TODO: change with textarea ufinet*/}
			<div className="mb-5 w-100">
				<label className="form-label">
					{translate('TICKET_DETAILS.COMMENT')} <span className="error-text">*</span>
				</label>
				<textarea
					className={`textarea-field ${errors.description ? 'textarea-error' : ''}`}
					value={values.description}
					onChange={onTextChange(formik, 'description')}
					rows={4}
				/>
				{errors.description && <div className="error-message">{errors.description}</div>}
			</div>

			<div className="d-flex gap-2 justify-content-end">
				<UfinetButton
					content={translate('BUTTON.SEND')}
					onClick={handleSubmit}
					className="mt-5"
					isDisabled={!(isValid && dirty)}
				/>
				<UfinetButton
					content={translate('BUTTON.CANCEL')}
					onClick={handleClose}
					className="mt-5"
					outline
				/>
			</div>
		</UfinetModal>
	)
}

export { NotesModal }
