import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { ServiceTypeRepository } from '../domain/repository/ServiceTypeRepository'
import { ServiceType } from '../domain/ServiceType'

export const HttpServiceTypeRepository: (authData: IAuthData) => ServiceTypeRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}serviceType`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<ServiceType[]> =>
			await FetchAPI.get(`${url}/GetServiceTypes`, {
				...fetchOptions,
				...options,
			}),
	}
}
