import { FC } from 'react'

const WelcomeKitPage: FC = () => {
	return (
		<div>
			<h2 className="text-center">Welcome Kit</h2>
		</div>
	)
}

export { WelcomeKitPage }
