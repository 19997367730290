import { useQuery } from 'react-query'
import { AffectationTypeRepository } from '../domain/repository/AffectationTypeRepository'
import { AffectationType } from '../domain/AffectationType'

export const useAffectionTypeFindAllQuery = (
	affectationTypeRepository: AffectationTypeRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (affectationType: AffectationType[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('affectation-type-find-all-query', () => affectationTypeRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
