import { FC, useCallback, useMemo } from 'react'

import { onFormikTextChanges, useTranslator } from 'ufinet-web-functions'

import { DatePicker, DatePickerTypeEnum, UfinetInput, UfinetSectionBox } from 'ufinet-web-components'

interface ContactInfoSectionProps {
	formik: any
}

const ContactInfoSection: FC<ContactInfoSectionProps> = ({ formik }) => {
	const translate = useTranslator()
	const { values } = formik

	const onTextChange = useCallback(onFormikTextChanges, [])
	const nowDate = useMemo(() => new Date(), [])

	return (
		<UfinetSectionBox title="contact_info" className="mb-5">
			<h4>{translate('TICKET.NEW.TITLE.VISIT.INFO')}</h4>
			<div className="row mt-3 mb-3">
				<UfinetInput
					type="text"
					value={values.visitContactName}
					id="visit_contact_name"
					solid={false}
					labelTitle={translate('TICKET.NEW.VISIT.CONTACT')}
					className="col-12 col-md-6"
					onChange={onTextChange(formik, 'visitContactName')}
				/>
				<UfinetInput
					type="text"
					value={values.visitContactPhone}
					id="visit_contact_phone"
					solid={false}
					labelTitle={translate('TICKET.NEW.VISIT.PHONE')}
					className="col-12 col-md-6"
					onChange={onTextChange(formik, 'visitContactPhone')}
				/>
			</div>
			<div className="row mt-3">
				<UfinetInput
					type="text"
					value={values.visitRequirements}
					id="visit_requirements"
					solid={false}
					labelTitle={translate('TICKET.NEW.VISIT.REQUIREMENTS')}
					className="col"
					onChange={onTextChange(formik, 'visitRequirements')}
				/>
			</div>
			<div className="row mt-3">
				<DatePicker
					type={DatePickerTypeEnum.DATE_TIME}
					timeFormat="24"
					value={values.visitDate}
					id="visiting_hours"
					label={translate('TICKET.NEW.VISIT.TIMESTAMP')}
					className="col"
					onChange={onTextChange(formik, 'visitDate')}
					min={nowDate}
				/>
			</div>
			{/* <div className="d-flex flex-row gap-9 w-100 mt-3">
				<label htmlFor="fichero">{translate('TICKET.NEW.FILE')}</label>
				<input type="file" name="fichero" value={values.fichero} onChange={onTextChange(formik, 'fichero')} />
			</div> */}
		</UfinetSectionBox>
	)
}

export { ContactInfoSection }
