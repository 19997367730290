import React, { FC } from 'react'

type PaginationProps = {
	currentPage: number;
	totalPages: number;
	onPageChange: (newPage: number) => void;
};

const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
	if (totalPages <= 1) return null

	const renderPageNumbers = () => {
		const pages = []
		const visiblePages = 2
		const startPage = Math.max(2, currentPage - visiblePages)
		const endPage = Math.min(totalPages - 1, currentPage + visiblePages)

		pages.push(
			<button
				key={1}
				onClick={() => onPageChange(1)}
				className={`pagination-button ${currentPage === 1 ? 'active' : ''}`}
			>
				1
			</button>,
		)

		if (startPage > 2) {
			pages.push(
				<span key="start-ellipsis" className="pagination-ellipsis">
                    ...
                </span>,
			)
		}

		for (let page = startPage; page <= endPage; page++) {
			pages.push(
				<button
					key={page}
					onClick={() => onPageChange(page)}
					className={`pagination-button ${currentPage === page ? 'active' : ''}`}
				>
					{page}
				</button>,
			)
		}

		if (endPage < totalPages - 1) {
			pages.push(
				<span key="end-ellipsis" className="pagination-ellipsis">...</span>,
			)
		}

		if (totalPages > 1) {
			pages.push(
				<button
					key={totalPages}
					onClick={() => onPageChange(totalPages)}
					className={`pagination-button ${currentPage === totalPages ? 'active' : ''}`}
				>
					{totalPages}
				</button>,
			)
		}

		return pages
	}

	return (
		<div className="pagination d-flex justify-content-center align-items-center">
			<button
				onClick={() => onPageChange(1)}
				className="pagination-button"
			>
				&lt;
			</button>
			{renderPageNumbers()}
			<button
				onClick={() => onPageChange(totalPages)}
				className="pagination-button"
			>
				&gt;
			</button>
		</div>
	)
}

export default Pagination
