import { gtiI18nMessages } from 'src/modules/i18n/domain/messages'
import { FC } from 'react'
import { I18nProvider, Ufineti18nProvider } from 'ufinet-web-components'

const GtiI18nProvider: FC = ({ children }) => {
	return (
		<Ufineti18nProvider>
			<I18nProvider messagesByLanguage={gtiI18nMessages}>{children}</I18nProvider>
		</Ufineti18nProvider>
	)
}

export { GtiI18nProvider }
