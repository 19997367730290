export const formatBandwidth = (value: string | null): string => {
	if (value) {
		const numValue = parseFloat(value)
		if (isNaN(numValue)) {
			return value
		}
		return numValue.toFixed(3).replace(/\.?0+$/, '')
	}
	return ''
}
