import { FC } from 'react'

const SupportPage: FC = () => {
	return (
		<div>
			<h2 className="text-center">Soporte</h2>
		</div>
	)
}

export { SupportPage }
