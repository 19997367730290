import { useQuery } from 'react-query'
import { ReportType } from 'src/modules/reportTypes/domain/ReportType'
import { ReportTypeRepository } from '../domain/repository/ReportTypeRepository'

export const useReportTypeFindAllQuery = (
	reportTypeRepository: ReportTypeRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (reportType: ReportType[]) => void
		onError?: (error: unknown) => void
	}
) => {
	return useQuery('report-type-find-all-query', () => reportTypeRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
