import { AppLanguage, IUfinetSelectOption } from 'ufinet-web-functions'

export class ServiceType {
	serviceTypeId: string
	nameEs: string
	nameEn: string
	namePt: string

	constructor(id: string, nameEs: string, nameEn: string, namePt: string) {
		this.serviceTypeId = id
		this.nameEs = nameEs
		this.nameEn = nameEn
		this.namePt = namePt
	}

	static mapServiceTypeToSelectOption(self: ServiceType, language: AppLanguage): IUfinetSelectOption {
		const label =
			language === 'en' && self.nameEn ? self.nameEn : language === 'pt' && self.namePt ? self.namePt : self.nameEs
		return {
			//TODO uncomment this line when the language is implemented
			// label: language === 'es' ? self.nameEs : language === 'en' ? self.nameEn : self.namePt,
			label: label,
			value: self.serviceTypeId,
		}
	}
}
