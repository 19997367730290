import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { ProcessStatus } from '../domain/ProcessStatus'
import { ProcessStatusRepository } from '../domain/repository/ProcessStatusRepository'

export const HttpProcessStatusRepository: (authData: IAuthData) => ProcessStatusRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}processStatus`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<ProcessStatus[]> =>
			await FetchAPI.get(`${url}/GetProcessStatus`, {
				...fetchOptions,
				...options,
			}),
	}
}
