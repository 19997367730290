import { AppLanguage, IUfinetSelectOption } from 'ufinet-web-functions'

export class ReportType {
	reportTypeId: string
	nameEs: string
	nameEn: string
	namePt: string

	constructor(id: string, nameEs: string, nameEn: string, namePt: string) {
		this.reportTypeId = id
		this.nameEs = nameEs
		this.nameEn = nameEn
		this.namePt = namePt
	}

	static mapReportTypeToSelectOption(self: ReportType, language: AppLanguage): IUfinetSelectOption {
		return {
			label: language === 'es' ? self.nameEs : language === 'en' ? self.nameEn : self.namePt,
			value: self.reportTypeId,
		}
	}
}
