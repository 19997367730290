import { AppLanguage, IUfinetSelectOption } from 'ufinet-web-functions'

export class ProcessStatus {
	processStatusId: string
	nameEs: string
	nameEn: string
	namePt: string

	constructor(id: string, nameEs: string, nameEn: string, namePt: string) {
		this.processStatusId = id
		this.nameEs = nameEs
		this.nameEn = nameEn
		this.namePt = namePt
	}

	static mapProcessStatusToSelectOption(self: ProcessStatus, language: AppLanguage): IUfinetSelectOption {
		return {
			label: language === 'es' ? self.nameEs : language === 'en' ? self.nameEn : self.namePt,
			value: self.processStatusId,
		}
	}
}
