export const PATH_HOME = ''

export const PATH_NEW_TICKET = 'new-ticket'
export const PATH_NEW_TICKET_CORP = 'new-ticket-corp'
export const PATH_NEW_TICKET_FTTH = 'new-ticket-ftth'
export const PATH_BULK_TICKETS = 'bulk-tickets'
export const PATH_LIST_TICKETS = 'list-tickets'
export const PATH_CORPORATE_TICKETS_DETAIL = 'corporate-tickets-detail'
export const PATH_FTTH_TICKETS_DETAIL = 'ftth-tickets-detail'

export const PATH_SUPPORT = 'soporte'
export const PATH_WELCOME_KIT = 'welcome-kit'

export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'PATH_HOME'],
	[PATH_NEW_TICKET, 'MENU.NEW_TICKET'],
	[PATH_NEW_TICKET_CORP, 'MENU.NEW_TICKET_CORP'],
	[PATH_NEW_TICKET_FTTH, 'MENU.NEW_TICKET_FTTH'],
	[PATH_BULK_TICKETS, 'MENU.BULK_TICKETS'],
	[PATH_LIST_TICKETS, 'MENU.LIST_TICKETS'],
	[PATH_SUPPORT, 'MENU.SUPPORT'],
	[PATH_WELCOME_KIT, 'MENU.WELCOME_KIT'],
	[PATH_CORPORATE_TICKETS_DETAIL, 'MENU.TICKETS_DETAIL'],
	[PATH_FTTH_TICKETS_DETAIL, 'MENU.TICKETS_DETAIL'],
])
