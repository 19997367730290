import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { ProjectType } from '../domain/ProjectType'
import { ProjectTypeRepository } from '../domain/repository/ProjectTypeRepository'

export const HttpProjectTypeRepository: (authData: IAuthData) => ProjectTypeRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}projectType`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<ProjectType[]> =>
			await FetchAPI.get(`${url}/GetProjectTypes`, {
				...fetchOptions,
				...options,
			}),
	}
}
