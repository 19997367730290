import { FC } from 'react'

import { useTranslator } from 'ufinet-web-functions'

import { UfinetInput, UfinetSectionBox } from 'ufinet-web-components'

interface DetailTicketIncidentInfoProps {
	ticketName: string | null
	ticketId: string | null
	creationDate: string
	ticketStatus: string
	lastStatus: string
	clientName: string | null
	reportType: string
	degradationType?: string | null
	incidentDescription: string | null
	reportOrigin: string
	clientTicketNumber: string | null
	reportUser: string | null
	notificationGroup: string | null
	isCorporate: boolean
}

const DetailTicketIncidentInfo: FC<DetailTicketIncidentInfoProps> = ({
	ticketName,
	ticketId,
	creationDate,
	ticketStatus,
	isCorporate,
	lastStatus,
	clientName,
	reportType,
	degradationType,
	incidentDescription,
	reportOrigin,
	clientTicketNumber,
	reportUser,
	notificationGroup,
}) => {
	const translate = useTranslator()

	return (
		<UfinetSectionBox title="ticket_info" className="mb-5 p-5">
			<h4 className="detail-ticket-titles">{translate('TICKET.NEW.TITLE.TICKET.INFO')}</h4>
			<div className="row">
				<UfinetInput
					className="col-12 col-md-3 mt-3"
					labelTitle={translate('TICKET_DETAILS.ID')}
					type="text"
					value={ticketId ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET_DETAILS.CREATION_DATE')}
					type="text"
					value={creationDate}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET_DETAILS.INCIDENT_TITLE')}
					type="text"
					value={ticketName ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('COL.TICKET.REPORT')}
					type="text"
					value={isCorporate ? translate('TICKET.NEW.CORPORATE') : translate('TICKET_DETAILS.FTTH_MULTI_CLIENT')}
					isDisabled
					solid={false}
				/>
			</div>

			<div className="row">
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('COL.TICKET.TICKET_STATUS')}
					type="text"
					value={ticketStatus}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET_DETAILS.LAST_STATUS')}
					type="text"
					value={lastStatus}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-5 mt-3"
					labelTitle={translate('TICKET_DETAILS.CLIENT_NAME')}
					type="text"
					value={clientName ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-3 mt-3"
					labelTitle={translate('COL.TICKET.REPORT_TYPE')}
					type="text"
					value={reportType}
					isDisabled
					solid={false}
				/>
			</div>

			<div className="row">
				{isCorporate && degradationType && (
					<UfinetInput
						className="col-12 col-md mt-3"
						labelTitle={translate('COL.TICKET.DEGRADATION_TYPE')}
						type="text"
						value={degradationType}
						isDisabled
						solid={false}
					/>
				)}
				<UfinetInput
					className={`${isCorporate && degradationType ? 'col-12 col-md-9 mt-3' : 'col-12 col-md mt-3'}`}
					labelTitle={translate('TICKET.NEW.INCIDENT_DESCRIPTION')}
					type="text"
					value={incidentDescription ?? ''}
					isDisabled
					solid={false}
				/>
			</div>
			<div className="row mt-3">
				<UfinetInput
					className="col-12 col-md-2 mt-3"
					labelTitle={translate('COL.TICKET.REPORT_ORIGIN')}
					type="text"
					value={reportOrigin}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('COL.TICKET.CLIENT_TICKET_NUMBER')}
					type="text"
					value={clientTicketNumber ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-3 mt-3"
					labelTitle={translate('TICKET.NEW.REPORT.USER')}
					type="text"
					value={reportUser ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-4 mt-3 mb-4 mb-md-0"
					labelTitle={translate('TICKET.NEW.NOTIFICATION_GROUP')}
					type="text"
					value={notificationGroup ?? ''}
					isDisabled
					solid={false}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { DetailTicketIncidentInfo }
