import { IUfinetSelectOption } from 'ufinet-web-functions'
import { FtthIncident } from '../../domain/FtthIncident'
import { convertToLocalTimeString } from 'src/utils/dateUtils'

export class FtthIncidentSaveRequest {
	ticket: FtthIncident

	constructor(ticket: FtthIncident) {
		this.ticket = ticket
	}

	static fromValues(obj: {
		affectedTypeId: IUfinetSelectOption<number>
		clientSelect: IUfinetSelectOption
		creationDate: Date
		degradationType: IUfinetSelectOption<number>
		detectedDate: Date
		fichero: string
		incidentDescription: string
		internalCustomerTicketNumber: string
		networkElementAffected: string
		networkElementTypeId: IUfinetSelectOption<number>
		notificationGroupId: IUfinetSelectOption
		notificationLanguageId: IUfinetSelectOption<number>
		affectedService: IUfinetSelectOption
		reportClassification: IUfinetSelectOption<number>
		reportContactEmail: string
		reportContactPhone: string
		reportType: IUfinetSelectOption<number>
		serviceId: string
		visitContactName: string
		visitContactPhone: string
		visitRequirements: string
		visitDate: Date
		emailUserApplication: string
	}) {


		const ticket: FtthIncident = {
			reportContactId: '',
			affectedTypeId: obj.affectedTypeId.value,
			customerId: obj.clientSelect.value,
			degradationTypeId: obj.degradationType.value || undefined,
			detectedDate: convertToLocalTimeString(obj.detectedDate),
			file: obj.fichero,
			description: obj.incidentDescription,
			internalCustomerTicketNumber: obj.internalCustomerTicketNumber || undefined,
			networkElementAffected: obj.networkElementAffected || undefined,
			networkElementTypeId: obj.networkElementTypeId.value || undefined,
			notificationGroupId: obj.notificationGroupId.value || undefined,
			notificationLanguageId: obj.notificationLanguageId.value,
			serviceAffectedId: obj.affectedService.value,
			administrativeCode: obj.affectedService.name || undefined,
			reportClassification: obj.reportClassification.value || undefined,
			reportContactEmail: obj.reportContactEmail,
			reportContactPhone: obj.reportContactPhone,
			reportOriginId: 3,
			reportType: obj.reportType.value,
			serviceId: obj.serviceId || undefined,
			typeId: 912790000,
			visitContactName: obj.visitContactName || undefined,
			visitContactPhone: obj.visitContactPhone || undefined,
			visitRequirements: obj.visitRequirements || undefined,
			visitingHours: convertToLocalTimeString(obj.visitDate),
			emailUserApplication: obj.emailUserApplication,
		}
		return new FtthIncidentSaveRequest(ticket)
	}
}
