import { rest } from 'msw'
import { ICorporateGroup } from 'ufinet-web-functions'
import { DegradationType } from 'src/modules/degradationTypes/domain/DegradationType'
import { NotificationGroup } from 'src/modules/notificationGroup/domain/NotificationGroup'
import { ReportType } from 'src/modules/reportTypes/domain/ReportType'
import { ServicesCustomer } from 'src/modules/servicesCustomer/domain/ServicesCustomer'

const baseHandlers = [
	rest.all(/^(?!.*api\/).*$/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
]

const customHandlers = [
	rest.get('/api/NotificationGroups', (req, res, ctx) => {
		const groupObject1 = new NotificationGroup('1', 'NotificationGroup 1')
		const groupObject2 = new NotificationGroup('2', 'NotificationGroup 2')
		const groupObject3 = new NotificationGroup('3', 'NotificationGroup 3')
		const response: NotificationGroup[] = [groupObject1, groupObject2, groupObject3]

		return res(ctx.status(200), ctx.json(response))
	}),
	rest.get('/api/serviceslist', (req, res, ctx) => {
		const serviceObject1 = new ServicesCustomer('1', 'AffectedService 1')
		const serviceObject2 = new ServicesCustomer('2', 'AffectedService 2')
		const serviceObject3 = new ServicesCustomer('3', 'AffectedService 3')
		const response: ServicesCustomer[] = [serviceObject1, serviceObject2, serviceObject3]
		return res(ctx.status(200), ctx.json(response))
	}),
	rest.get('/api/reporttype', (req, res, ctx) => {
		const reportTypeObject1 = new ReportType('1', 'ReportType 1', 'ReportType 1', 'ReportType 1')
		const reportTypeObject2 = new ReportType('2', 'ReportType 2', 'ReportType 2', 'ReportType 2')
		const reportTypeObject3 = new ReportType('3', 'ReportType 3', 'ReportType 3', 'ReportType 3')
		const response: ReportType[] = [reportTypeObject1, reportTypeObject2, reportTypeObject3]
		return res(ctx.status(200), ctx.json(response))
	}),
	rest.get('/api/degradationtype', (req, res, ctx) => {
		const degradationObject1 = new DegradationType('1', 'DegradationType 1', 'DegradationType 1', 'DegradationType 1')
		const degradationObject2 = new DegradationType('2', 'DegradationType 2', 'DegradationType 2', 'DegradationType 2')
		const degradationObject3 = new DegradationType('3', 'DegradationType 3', 'DegradationType 3', 'DegradationType 3')
		const response: DegradationType[] = [degradationObject1, degradationObject2, degradationObject3]
		return res(ctx.status(200), ctx.json(response))
	}),
	rest.post('/api/form', (req, res, ctx) => {
		return res(ctx.status(204))
	}),
	rest.get(`http://localhost:8091/api/corporate-group`, (req, res, ctx) => {
		const response: ICorporateGroup[] = [
			{ id: '1', name: 'CG1', lastUpdatedBy: 'a', lastUpdatedAt: 'c' },
			{ id: '2', name: 'CG2', lastUpdatedBy: 'b', lastUpdatedAt: 'd' },
		]
		return res(ctx.status(200), ctx.json(response))
	}),
]

export const handlers = [...baseHandlers, ...customHandlers]
