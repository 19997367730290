import { useQuery } from 'react-query'

import { ServiceTypeRepository } from '../domain/repository/ServiceTypeRepository'
import { ServiceType } from '../domain/ServiceType'

export const useServiceTypeFindAllQuery = (
	serviceTypeRepository: ServiceTypeRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (serviceType: ServiceType[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('service-type-find-all-query', () => serviceTypeRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
