import { FC } from 'react'

import { useTranslator } from 'ufinet-web-functions'

import { faEye } from '@fortawesome/free-solid-svg-icons'

import { TooltipButton, UfinetCheckbox } from 'ufinet-web-components'

interface ColumnsHiddenHandlerProps {
	visibleColumns: { [key: string]: boolean }
	setVisibleColumns: (value: { [key: string]: boolean }) => void
}

const ColumnsHiddenHandler: FC<ColumnsHiddenHandlerProps> = ({ visibleColumns, setVisibleColumns }) => {
	const translate = useTranslator()

	const columns = [
		// { key: 'tickerNumber', translationKey: 'COL.TICKET.TICKET_ID' },
		// { key: 'administrativeCode', translationKey: 'COL.TICKET.ADMINISTRATIVE_CODE' },
		// { key: 'title', translationKey: 'COL.TICKET.INCIDENT_TITLE' },
		{ key: 'country', translationKey: 'COL.TICKET.COUNTRY' },
		// { key: 'customer', translationKey: 'COL.TICKET.CLIENT' },
		// { key: 'projectTypes', translationKey: 'COL.TICKET.REPORT' },
		{ key: 'reportTypes', translationKey: 'COL.TICKET.REPORT_TYPE' },
		{ key: 'degradationTypes', translationKey: 'COL.TICKET.DEGRADATION_TYPE' },
		{ key: 'affectationTypes', translationKey: 'COL.TICKET.AFFECTATION_TYPE' },
		{ key: 'reportOrigins', translationKey: 'COL.TICKET.REPORT_ORIGIN' },
		// { key: 'internalClientTicketNumber', translationKey: 'COL.TICKET.CLIENT_TICKET_NUMBER' },
		{ key: 'serviceTypes', translationKey: 'COL.TICKET.SERVICE_TYPE' },
		{ key: 'incidentStatues', translationKey: 'COL.TICKET.TICKET_STATUS' },
		// { key: 'createdDate', translationKey: 'COL.TICKET.CREATION_DATE' },
		{ key: 'solutionTypes', translationKey: 'COL.TICKET.SOLUTION_TYPE' },
		{ key: 'primaryCause', translationKey: 'COL.TICKET.MAIN_CAUSE' },
		{ key: 'secondaryCause', translationKey: 'COL.TICKET.SECONDARY_CAUSE' },
		{ key: 'imputableTo', translationKey: 'COL.TICKET.IMPUTABLE_TO' },
		{ key: 'serviceRestorationDate', translationKey: 'COL.TICKET.REESTABLISHMENT_DATE' },
		{ key: 'statusCloseDate', translationKey: 'COL.TICKET.CLOSED_DATE' },
		{ key: 'totalImputableTime', translationKey: 'COL.TICKET.TOTAL_IMPUTABLE_TIME' },
		{ key: 'rfoSentStatuses', translationKey: 'COL.TICKET.RFO_SENT' },
		{ key: 'rfoSentDate', translationKey: 'COL.TICKET.RFO_DATE' },
		{ key: 'descriptionDiscountTime', translationKey: 'COL.TICKET.DISCOUNT_TIME_REASON' },
	]

	return (
		<TooltipButton
			behaviour="click"
			active={true}
			buttonProps={{ content: 'Columnas', icon: faEye }}
			tooltipProps={{ placement: 'auto' }}
		>
			{columns.map(({ key, translationKey }) => (
				<UfinetCheckbox
					key={key}
					title={translate(translationKey)}
					checked={visibleColumns[key] === true}
					className="mb-2"
					value={key}
					onChange={() => setVisibleColumns({ ...visibleColumns, [key]: !visibleColumns[key] })}
				/>
			))}
		</TooltipButton>
	)
}

export { ColumnsHiddenHandler }
