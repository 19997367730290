import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { ReportClassification } from '../domain/ReportClassification'
import { ReportClassificationRepository } from '../domain/repository/ReportClassificationRepository'

export const HttpReportClassificationRepository: (authData: IAuthData) => ReportClassificationRepository = (
	authData
) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}reportClassification`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<ReportClassification[]> =>
			await FetchAPI.get(`${url}/GetReportClassifications`, {
				...fetchOptions,
				...options,
			}),
	}
}
