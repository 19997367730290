import { AppLanguage, IUfinetSelectOption } from 'ufinet-web-functions'

export class AffectationType {
	affectationTypeId: string
	nameEs: string
	nameEn: string
	namePt: string

	constructor(id: string, nameEs: string, nameEn: string, namePt: string) {
		this.affectationTypeId = id
		this.nameEs = nameEs
		this.nameEn = nameEn
		this.namePt = namePt
	}

	static mapAffectationTypesToSelectOption(self: AffectationType, language: AppLanguage): IUfinetSelectOption {
		return {
			label: language === 'es' ? self.nameEs : language === 'en' ? self.nameEn : self.namePt,
			value: self.affectationTypeId,
		}
	}
}
