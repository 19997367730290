import { DegradationType } from 'src/modules/degradationTypes/domain/DegradationType'
import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { DegradationTypeRepository } from '../domain/repository/DegradationTypeRepository'

export const HttpDegradationTypeRepository: (authData: IAuthData) => DegradationTypeRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}degradationType`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<DegradationType[]> =>
			await FetchAPI.get(`${url}/GetDegradationType`, {
				...fetchOptions,
				...options,
			}),
	}
}
