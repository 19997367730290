import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import dayjs from 'dayjs'
import { IncidentRow } from 'src/modules/incidents/domain/IncidentRow'
import { formatCellNumber, IUfinetSelectOption } from 'ufinet-web-functions'

import { faEye } from '@fortawesome/free-solid-svg-icons'

import { ColData } from 'ufinet-web-components'

function formatDate(date: string | null, withTime?: boolean) {
	if (date) {
		if (withTime) {
			return dayjs(date).format('DD/MM/YYYY HH:mm')
		}
		return dayjs(date).format('DD/MM/YYYY')
	}
	return ''
}
function formatRfoSentStatus(status: boolean | null, translate: (key: string) => string): string | null {
	if (status === null) return null;
	return status ? translate('RFO_SENT_STATUS.SENT') : translate('RFO_SENT_STATUS.NOT_SENT');
}

export const incidentsColsData = (
	translate: (key: string) => string,
	navigateTo: (row: IncidentRow) => void,
	visibleColumns: { [key: string]: boolean },
	reportTypes: IUfinetSelectOption[],
	degradationTypes: IUfinetSelectOption[],
	affectationTypes: IUfinetSelectOption[],
	projectTypes: IUfinetSelectOption[],
	processStatus: IUfinetSelectOption[],
	reportOrigins: IUfinetSelectOption[],
	rfoSentStatus: IUfinetSelectOption[],
	serviceTypes: IUfinetSelectOption[],
	solutionTypes: IUfinetSelectOption[]
): ColData[] => [
	{
		field: 'detail',
		header: 'COL.TICKET.DETAIL',
		body: (row: IncidentRow) => (
			<div className="ufinet-table-cell w-100 d-flex align-items-center justify-content-center">
				<FontAwesomeIcon icon={faEye} fontSize={15} onClick={() => navigateTo(row)} />
			</div>
		),
		nonSortable: true,
		nonFilterable: true,
		nonSearchable: true,
		width: '75px',
	},
	{
		field: 'tickerNumber',
		header: 'COL.TICKET.TICKET_ID',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.ticketNumber}</div>,
		nonSortable: true,
		nonFilterable: true,
		width: '200px',
		hidden: !visibleColumns.tickerNumber,
	},
	{
		field: 'serviceId',
		header: 'COL.TICKET.ADMINISTRATIVE_CODE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.administrativeCode}</div>,
		nonFilterable: true,
		nonSearchable: true,
		width: '250px',
		hidden: !visibleColumns.administrativeCode,
	},
	{
		field: 'title',
		header: 'COL.TICKET.INCIDENT_TITLE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.ticketName}</div>,
		nonSortable: true,
		nonFilterable: true,
		width: '300px',
		hidden: !visibleColumns.title,
	},
	{
		field: 'country',
		header: 'COL.TICKET.COUNTRY',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.countryName}</div>,
		nonSortable: true,
		nonSearchable: true,
		nonFilterable: true,
		width: '150px',
		hidden: !visibleColumns.country,
	},
	{
		field: 'customer',
		header: 'COL.TICKET.CLIENT',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.customerName}</div>,
		nonSortable: true,
		nonSearchable: true,
		nonFilterable: true,
		width: '250px',
		hidden: !visibleColumns.customer,
	},
	{
		field: 'projectTypesId',
		header: 'COL.TICKET.REPORT',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.projectType}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: projectTypes,
		width: '200px',
		hidden: !visibleColumns.projectTypes,
	},
	{
		field: 'reportTypesId',
		header: 'COL.TICKET.REPORT_TYPE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.reportTypeName}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: reportTypes,
		width: '155px',
		hidden: !visibleColumns.reportTypes,
	},
	{
		field: 'degradationTypesId',
		header: 'COL.TICKET.DEGRADATION_TYPE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.degradationTypeName}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: degradationTypes,
		width: '180px',
		hidden: !visibleColumns.degradationTypes,
	},
	{
		field: 'affectationTypesId',
		header: 'COL.TICKET.AFFECTATION_TYPE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.affectationTypeName}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: affectationTypes,
		width: '170px',
		hidden: !visibleColumns.affectationTypes,
	},
	{
		field: 'reportOriginsId',
		header: 'COL.TICKET.REPORT_ORIGIN',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.reportOriginName}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: reportOrigins,
		width: '175px',
		hidden: !visibleColumns.reportOrigins,
	},
	{
		field: 'internalClientTicketNumber',
		header: 'COL.TICKET.CLIENT_TICKET_NUMBER',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.internalTicketNumber}</div>,
		nonSortable: true,
		nonFilterable: true,
		width: '200px',
		hidden: !visibleColumns.internalClientTicketNumber,
	},
	{
		field: 'serviceTypesId',
		header: 'COL.TICKET.SERVICE_TYPE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.serviceType}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: serviceTypes,
		width: '155px',
		hidden: !visibleColumns.serviceTypes,
	},
	{
		field: 'incidentStatuesId',
		header: 'COL.TICKET.TICKET_STATUS',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.status}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: processStatus,
		width: '160px',
		hidden: !visibleColumns.incidentStatues,
	},
	{
		field: 'createdDate',
		header: 'COL.TICKET.CREATION_DATE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{formatDate(row.creationDate, true)}</div>,
		nonFilterable: true,
		nonSearchable: true,
		width: '210px',
		hidden: !visibleColumns.createdDate,
	},
	{
		field: 'solutionTypesId',
		header: 'COL.TICKET.SOLUTION_TYPE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.solutionType}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: solutionTypes,
		width: '160px',
		hidden: !visibleColumns.solutionTypes,
	},
	{
		field: 'primaryCause',
		header: 'COL.TICKET.MAIN_CAUSE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.primaryCauseName}</div>,
		nonSortable: true,
		nonSearchable: true,
		nonFilterable: true,
		width: '125px',
		hidden: !visibleColumns.primaryCause,
	},
	{
		field: 'secondaryCause',
		header: 'COL.TICKET.SECONDARY_CAUSE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.secondaryCauseName}</div>,
		nonSortable: true,
		nonSearchable: true,
		nonFilterable: true,
		width: '140px',
		hidden: !visibleColumns.secondaryCause,
	},
	{
		field: 'imputableTo',
		header: 'COL.TICKET.IMPUTABLE_TO',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.attributableTo}</div>,
		nonSortable: true,
		nonSearchable: true,
		nonFilterable: true,
		width: '150px',
		hidden: !visibleColumns.imputableTo,
	},
	{
		field: 'serviceRestorationDate',
		header: 'COL.TICKET.REESTABLISHMENT_DATE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{formatDate(row.serviceRestorationDate, true)}</div>,
		nonFilterable: true,
		nonSearchable: true,
		width: '260px',
		hidden: !visibleColumns.serviceRestorationDate,
	},
	{
		field: 'statusCloseDate',
		header: 'COL.TICKET.CLOSED_DATE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{formatDate(row.statusCloseDate, true)}</div>,
		nonFilterable: true,
		nonSearchable: true,
		width: '185px',
		hidden: !visibleColumns.statusCloseDate,
	},
	{
		field: 'totalImputableTime',
		header: 'COL.TICKET.TOTAL_IMPUTABLE_TIME',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{formatCellNumber(row.totalImputableTime)}</div>,
		nonFilterable: true,
		nonSearchable: true,
		width: '230px',
		hidden: !visibleColumns.totalImputableTime,
	},
	{
		field: 'rfoSentStatuses',
		header: 'COL.TICKET.RFO_SENT',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{formatRfoSentStatus(row.rfoSentStatus, translate)}</div>,
		nonSortable: true,
		nonSearchable: true,
		filterOptions: rfoSentStatus,
		width: '160px',
		hidden: !visibleColumns.rfoSentStatuses,
	},
	{
		field: 'rfoSentDate',
		header: 'COL.TICKET.RFO_DATE',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{formatDate(row.rfoSentDate, true)}</div>,
		nonSortable: true,
		nonFilterable: true,
		width: '150px',
		hidden: !visibleColumns.rfoSentDate,
	},
	{
		field: 'descriptionDiscountTime',
		header: 'COL.TICKET.DISCOUNT_TIME_REASON',
		body: (row: IncidentRow) => <div className="ufinet-table-cell">{row.descriptionDiscountTime}</div>,
		nonSortable: true,
		nonFilterable: true,
		width: '210px',
		hidden: !visibleColumns.descriptionDiscountTime,
	},
]
