import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { NetworkElementType } from '../domain/NetworkElementType'
import { NetworkElementTypeRepository } from '../domain/repository/NetworkElementTypeRepository'

export const HttpNetworkElementTypeRepository: (authData: IAuthData) => NetworkElementTypeRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}networkElementType`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<NetworkElementType[]> =>
			await FetchAPI.get(`${url}/GetNetworkElementTypes`, {
				...fetchOptions,
				...options,
			}),
	}
}
