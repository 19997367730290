import { NotificationGroup } from 'src/modules/notificationGroup/domain/NotificationGroup'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { NotificationGroupRepository } from '../domain/repository/NotificationGroupRepository'

export const HttpNotificationGroupRepository: (authData: IAuthData) => NotificationGroupRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}notificationGroups`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (corporativeGroupId: string, options?: FetchOptions): Promise<NotificationGroup[]> =>
			await FetchAPI.get_params(
				`${url}/GetNotificationGroups`,
				{ corporativeGroupId },
				{
					...fetchOptions,
					...options,
				}
			),
	}
}
