import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { CorporateIncidentSaveRequest } from '../application/dto/CorporateIncidentSaveRequest'
import { CorporativeIncidentFindResponse } from '../application/dto/CorporativeIncidentFindResponse'
import { FTTHIncidentFindResponse } from '../application/dto/FTTHIncidentFindResponse'
import { FtthIncidentSaveRequest } from '../application/dto/FtthIncidentSaveRequest'
import { IncidentFindRequest } from '../application/dto/IncidentFindRequest'
import { IncidentFindResponse } from '../application/dto/IncidentFindResponse'
import { IncidentRepository } from '../domain/repository/IncidentRepository'
import { IncidentActivityFindRequest } from '../application/dto/IncidentActivityFindRequest'
import { IncidentActivityFindResponse } from '../application/dto/IncidentActivityFindResponse'
import { CreateIncidentActivityDTO } from '../application/dto/CreateIncidentActivityDTO'

const url = `${process.env.REACT_APP_URL_BACK_GTI}incident`
const incidentActivityURL = `${process.env.REACT_APP_URL_BACK_GTI}incidentActivity`


export const HttpIncidentRepository: (authData: IAuthData) => IncidentRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
		notifyError: false
	}

	return {
		saveCorporateIncident: async (ticket: CorporateIncidentSaveRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.post(`${url}/createCorporateIncidents`, [ticket.ticket], {
				...fetchOptions,
				...options,
			}),
		saveFtthIncident: async (ticket: FtthIncidentSaveRequest, options?: FetchOptions): Promise<void> =>
			await FetchAPI.post(`${url}/createFTTHIncidents`, [ticket.ticket], {
				...fetchOptions,
				...options,
			}),
		findAll: async (request: IncidentFindRequest, options?: FetchOptions): Promise<IncidentFindResponse> => {
			const params = new URLSearchParams()

			for (const key in request) {
				if (request[key] !== undefined && request[key] !== null && request[key] !== '') {
					if (Array.isArray(request[key])) {
						request[key].forEach((value: string) => {
							if (value !== undefined && value !== null && value !== '') {
								params.append(key, value)
							}
						})
					} else {
						params.append(key, request[key])
					}
				}
			}

			return await FetchAPI.get(`${url}/findAll?${params.toString()}`, {
				...fetchOptions,
				...options,
			})
		},
		findCorporateIncident: async (
			incidentId: string,
			options?: FetchOptions,
		): Promise<CorporativeIncidentFindResponse> =>
			await FetchAPI.get_params(
				`${url}/findCorporateIncident`,
				{ incidentId },
				{
					...fetchOptions,
					...options,
				},
			),
		findFTTHIncident: async (incidentId: string, options?: FetchOptions): Promise<FTTHIncidentFindResponse> =>
			await FetchAPI.get_params(
				`${url}/findFTTHIncident`,
				{ incidentId },
				{
					...fetchOptions,
					...options,
				},
			),
		findIncidentActivities: async (
			request: IncidentActivityFindRequest,
			options?: FetchOptions,
		): Promise<IncidentActivityFindResponse> => {
			const params = new URLSearchParams({
				incidentId: request.incidentId,
				pageNumber: request.pageNumber.toString(),
				RowsPerPage: request.rowsPerPage.toString(),
			})

			return await FetchAPI.get(`${incidentActivityURL}/findByIncidentId?${params.toString()}`, {
				...fetchOptions,
				...options,
			})
		},
		createIncidentActivity: async (
			activity: CreateIncidentActivityDTO,
			options?: FetchOptions,
		): Promise<void> => {
			await FetchAPI.post(`${incidentActivityURL}/CreateIncidentActivity`, activity, {
				...fetchOptions,
				...options,
			})
		},
	}
}
