import { useQuery } from 'react-query'

import { ProcessStatus } from '../domain/ProcessStatus'
import { ProcessStatusRepository } from '../domain/repository/ProcessStatusRepository'

export const useProcessStatusFindAllQuery = (
	processStatusRepository: ProcessStatusRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (processStatus: ProcessStatus[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('process-status-find-all-query', () => processStatusRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
