import { IUfinetSelectOption } from 'ufinet-web-functions'
import { CorporateIncident } from '../../domain/CorporateIncident'
import { convertToLocalTimeString } from 'src/utils/dateUtils'

export class CorporateIncidentSaveRequest {
	ticket: CorporateIncident;

	constructor(ticket: CorporateIncident) {
		this.ticket = ticket;
	}

	static fromValues(obj: {
		reportContactId: IUfinetSelectOption
		reportEmail: string
		notificationGroup: IUfinetSelectOption
		reportPhone: string
		creationDate: Date
		notificationLang: IUfinetSelectOption<number>
		affectedService: IUfinetSelectOption
		internalCustomerTicketNumber: string
		reportType: IUfinetSelectOption<number>
		degradationType: IUfinetSelectOption<number>
		detectionTimestamp: Date
		incidentDescription: string
		visitDate: Date
		visitContact: string
		visitPhone: string
		visitRequirements: string
		fichero: string
		countrySelect: IUfinetSelectOption
		corporateGroupSelect: IUfinetSelectOption
		clientSelect: IUfinetSelectOption
		emailUserApplication: string
	}) {
		const ticket: CorporateIncident = {
			notificationGroupId: obj.notificationGroup.value || undefined,
			reportContactId: obj.reportContactId.value,
			reportContactEmail: obj.reportEmail,
			reportContactPhone: obj.reportPhone,
			observation: '',
			notificationLanguageId: obj.notificationLang.value,
			customerId: obj.clientSelect.value,
			serviceAffectedId: obj.affectedService.value,
			administrativeCode: obj.affectedService.name || undefined,
			reportTypeId: obj.reportType.value,
			degradationTypeId: obj.degradationType.value || undefined,
			detectedDate: convertToLocalTimeString(obj.detectionTimestamp),
			description: obj.incidentDescription,
			internalCustomerTicketNumber: obj.internalCustomerTicketNumber || undefined,
			visitingHours: convertToLocalTimeString(obj.visitDate),
			visitContactName: obj.visitContact,
			visitContactPhone: obj.visitPhone,
			visitRequirements: obj.visitRequirements,
			file: obj.fichero,
			typeId: 912790000,
			reportOriginId: 3,
			emailUserApplication: obj.emailUserApplication,
		};
		return new CorporateIncidentSaveRequest(ticket);
	}
}
