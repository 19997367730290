import { ReportType } from 'src/modules/reportTypes/domain/ReportType'
import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { ReportTypeRepository } from '../domain/repository/ReportTypeRepository'

export const HttpReportTypeRepository: (authData: IAuthData) => ReportTypeRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}reportType`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<ReportType[]> =>
			await FetchAPI.get(`${url}/GetReportType`, {
				...fetchOptions,
				...options,
			}),
	}
}
