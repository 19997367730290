import { FC } from 'react'

import { formatBandwidth } from 'src/utils/formatBandwidth'
import { useTranslator } from 'ufinet-web-functions'

import { UfinetInput, UfinetSectionBox } from 'ufinet-web-components'

interface DetailTicketServiceInfoProps {
	administrativeCode: string | null
	originCountryName: string | null
	destinationCountryName: string | null
	serviceType: string
	transmissionMedium: string | null
	bandwidthCapacity: string | null
	vlanClient: string | null
	publicIp: string | null
}

const DetailTicketServiceInfo: FC<DetailTicketServiceInfoProps> = ({
	administrativeCode,
	originCountryName,
	destinationCountryName,
	serviceType,
	transmissionMedium,
	bandwidthCapacity,
	vlanClient,
	publicIp,
}) => {
	const translate = useTranslator()
	return (
		<UfinetSectionBox title="service_info" className="mb-5 p-5">
			<h4 className="detail-ticket-titles">{translate('TICKET_DETAILS.SERVICE_INFO')}</h4>
			<div className="row">
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('COL.TICKET.ADMINISTRATIVE_CODE')}
					type="text"
					value={administrativeCode ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET_DETAILS.COUNTRY_ORIGIN')}
					type="text"
					value={originCountryName ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET_DETAILS.COUNTRY_DESTINATION')}
					type="text"
					value={destinationCountryName ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('COL.TICKET.SERVICE_TYPE')}
					type="text"
					value={serviceType}
					isDisabled
					solid={false}
				/>
			</div>

			<div className="row">
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET_DETAILS.TRANSMISSION_TYPE')}
					type="text"
					value={transmissionMedium ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET_DETAILS.CAPACITY')}
					type="text"
					value={formatBandwidth(bandwidthCapacity)}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3"
					labelTitle={translate('TICKET_DETAILS.CLIENT_VLAN')}
					type="text"
					value={vlanClient ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md mt-3 mb-4 mb-md-0"
					labelTitle={translate('TICKET_DETAILS.PUBLIC_IP')}
					type="text"
					value={publicIp ?? ''}
					isDisabled
					solid={false}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { DetailTicketServiceInfo }
