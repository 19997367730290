import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { SolutionTypeRepository } from '../domain/repository/SolutionTypeRepository'
import { SolutionType } from '../domain/SolutionType'

export const HttpSolutionTypeRepository: (authData: IAuthData) => SolutionTypeRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}solutionType`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<SolutionType[]> =>
			await FetchAPI.get(`${url}/GetSolutionTypes`, {
				...fetchOptions,
				...options,
			}),
	}
}
