import { AppLanguage, defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { ServicesCustomerRepository } from '../domain/repository/ServicesCustomerRepository'
import { ServicesCustomer } from '../domain/ServicesCustomer'

export const HttpServicesCustomerRepository: (authData: IAuthData) => ServicesCustomerRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}servicesCustomer`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		corporateFindAll: async (
			customerId: string,
			language: AppLanguage,
			searchKeyword: string,
			options?: FetchOptions
		): Promise<ServicesCustomer> =>
			await FetchAPI.get_params(
				`${url}/GetCorporateServicesCustomer`,
				{ customerId, language, searchKeyword, rowsPerPage: 100, pageNumber: 0 },
				{
					...fetchOptions,
					...options,
				}
			),
		ftthFindAll: async (
			customerId: string,
			language: AppLanguage,
			searchKeyword: string,
			options?: FetchOptions
		): Promise<ServicesCustomer> =>
			await FetchAPI.get_params(
				`${url}/GetFtthServicesCustomer`,
				{ customerId, language, searchKeyword, rowsPerPage: 100, pageNumber: 0 },
				{
					...fetchOptions,
					...options,
				}
			),
	}
}
