import { useQuery } from 'react-query'
import { NotificationLanguage } from 'src/modules/notificationLanguages/domain/NotificationLanguage'
import { NotificationLanguageRepository } from '../domain/repository/NotificationLanguageRepository'

export const useNotificationLanguageFindAllQuery = (
	notifificationLangRepository: NotificationLanguageRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (notificationLang: NotificationLanguage[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('notification-language-find-all-query', () => notifificationLangRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
