import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import {
	AuthContext,
	IUfinetSelectOption,
	onFormikChanges,
	onFormikTextChanges,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'

import {
	ContactSelectHandle,
	DatePicker,
	DatePickerTypeEnum,
	Filter,
	IDisabledOptions,
	IFilterHiddenOptions,
	IFilterState,
	IRequiredOptions,
	UfinetInput,
	UfinetSectionBox,
	UfinetSelect,
} from 'ufinet-web-components'
import { useNotificationLanguageFindAllQuery } from '../../../../modules/notificationLanguages/application/NotificationLanguageQueries'
import { NotificationLanguage } from '../../../../modules/notificationLanguages/domain/NotificationLanguage'
import { HttpNotificationLanguageRepository } from '../../../../modules/notificationLanguages/infrastructure/HttpNotificationLanguageRepository'

interface TicketInfoSectionProps {
	formik: any
	isInternal: boolean
	notificationGroups: IUfinetSelectOption[]
	setNotificationGroups: (groups: IUfinetSelectOption[]) => void
	getNotificationGroups: (corporativeGroupId: string) => void
	setServicesCustomer: (services: IUfinetSelectOption[]) => void
	getServicesCustomer: (customerId: string) => void
}

const TicketInfoSection: FC<TicketInfoSectionProps> = ({
	formik,
	isInternal,
	notificationGroups,
	setNotificationGroups,
	getNotificationGroups,
	setServicesCustomer,
	getServicesCustomer,
}) => {
	const translate = useTranslator()
	const { values, errors } = formik
	const [notificationLangs, setNotificationLangs] = useState<IUfinetSelectOption[]>()

	const contactRef = useRef<ContactSelectHandle>(null)
	const authData = useContext(AuthContext)
	const language = useLang()

	const onChange = useCallback(onFormikChanges, [])
	const onTextChange = useCallback(onFormikTextChanges, [])

	const notificationLangRepository = useMemo(() => HttpNotificationLanguageRepository(authData), [authData])

	useEffect(() => {
		if (values.clientSelect?.value) {
			const request = {
				clientIds: [values.clientSelect.value],
			}
			contactRef.current?.fillSelect(request)
		}
	}, [values.clientSelect])

	const { isLoading: loadingNotificationLangs } = useNotificationLanguageFindAllQuery(notificationLangRepository, {
		onSuccess: (params: NotificationLanguage[]): void => {
			const mappedData = params.map((item) =>
				NotificationLanguage.mapNotificationLanguageToSelectOption(item, language)
			)
			setNotificationLangs(mappedData)
		},
	})

	const filterState: IFilterState = {
		countrySelect: null,
		corporateGroupSelect: null,
		clientSelect: null,
	}

	const requiredOptions: IRequiredOptions = {
		requiredCountry: true,
		requiredCorporateGroup: true,
		requiredClient: true,
		requiredContact: false,
		requiredReference: false,
		requiredFinalClient: false,
	}

	const hiddenOptions: IFilterHiddenOptions = {
		hideCountry: false,
		hideCorporateGroup: false,
		hideClient: false,
		hideContact: true,
		hideReference: true,
		hideFinalClient: true,
		hideSubmit: true,
	}

	const disabledOptions: IDisabledOptions = {
		allowCountrySelection: true,
		allowCorporateGroupSelection: Boolean(formik.values.countrySelect?.value),
		allowClientSelection: Boolean(formik.values.corporateGroupSelect?.value),
		allowContact: false,
		allowReference: false,
		allowFinalClient: false,
		allowSubmit: false,
	}

	return (
		<UfinetSectionBox title="ticket_info" className="mb-5">
			<h4>{translate('TICKET.NEW.TITLE.TICKET.INFO')}</h4>
			<div>
				<Filter
					internalUser={isInternal}
					setFilter={() => filterState}
					required={requiredOptions}
					hidden={hiddenOptions}
					disabled={disabledOptions}
					afterCountryChange={onChange(formik, 'countrySelect')}
					afterCorporateGroupChange={(val) => {
						onChange(formik, 'corporateGroupSelect')(val)
						if (val) {
							getNotificationGroups(val.value)
						} else {
							setNotificationGroups([])
						}
					}}
					afterClientChange={(val) => {
						onChange(formik, 'clientSelect')(val)
						if (val) {
							getServicesCustomer(val.value)
						} else {
							setServicesCustomer([])
						}
					}}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={values.reportContactEmail}
					id="report_email"
					isDisabled
					requiredIcon
					solid={false}
					error={errors.reportContactEmail}
					labelTitle={translate('TICKET.NEW.REPORT.EMAIL')}
					className="col-12 col-md-6"
					onChange={onTextChange(formik, 'reportContactEmail')}
				/>
				<UfinetSelect
					options={notificationGroups}
					value={values.notificationGroupId}
					id="notification_group"
					labelTitle={translate('TICKET.NEW.NOTIFICATION_GROUP')}
					className="col-12 col-md-6"
					onChange={onChange(formik, 'notificationGroupId')}
					isDisabled={!values.corporateGroupSelect?.value}
				/>
			</div>
			<div className="row mt-3">
				<UfinetInput
					type="text"
					value={values.reportContactPhone}
					id="report_phone"
					requiredIcon
					solid={false}
					error={errors.reportContactPhone}
					labelTitle={translate('TICKET.NEW.REPORT.PHONE')}
					className="col-12 col-md-4"
					onChange={onTextChange(formik, 'reportContactPhone')}
				/>
				<DatePicker
					value={values.creationDate}
					timeFormat="24"
					type={DatePickerTypeEnum.DATE_TIME}
					id="creation_timestamp"
					label={translate('TICKET.NEW.CREATION_DATE')}
					className="col-12 col-md-4"
					disabled
					onChange={() => {}}
				/>
				<UfinetSelect
					options={notificationLangs}
					isLoadingOptions={loadingNotificationLangs}
					value={values.notificationLanguageId}
					error={errors.notificationLanguageId?.label}
					id="notification_lang"
					requiredIcon
					labelTitle={translate('TICKET.NEW.NOTIFICATION_LANGUAGE')}
					className="col-12 col-md-4"
					onChange={onChange(formik, 'notificationLanguageId')}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { TicketInfoSection }
