import { AppLanguage, IUfinetSelectOption } from 'ufinet-web-functions'

export class SolutionType {
	solutionTypeId: string
	nameEs: string
	nameEn: string
	namePt: string

	constructor(id: string, nameEs: string, nameEn: string, namePt: string) {
		this.solutionTypeId = id
		this.nameEs = nameEs
		this.nameEn = nameEn
		this.namePt = namePt
	}

	static mapSolutionTypeToSelectOption(self: SolutionType, language: AppLanguage): IUfinetSelectOption {
		const label =
			language === 'en' && self.nameEn ? self.nameEn : language === 'pt' && self.namePt ? self.namePt : self.nameEs
		return {
			//TODO uncomment this line when the language is implemented
			// label: language === 'es' ? self.nameEs : language === 'en' ? self.nameEn : self.namePt,
			label: label,
			value: self.solutionTypeId,
		}
	}
}
